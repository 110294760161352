import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { PageProps } from "gatsby"
import Section from "../components/Section"
import Wrapper from "../components/Wrapper"
import LegalDatenschutz from "../components/legal/datenschutz"
import SEO from "../components/SEO"

export default function Datenschutz(props: PageProps) {
  return (
    <React.Fragment>
      <SEO titleAddition="Datenschutz" />
      <Header />
      <div
        className="w-full overflow-x-hidden flex flex-col  text-gray-700"
        css={{
          minHeight: "calc(100vh - 5rem)",
        }}
      >
        <Section>
          <Wrapper>
            <LegalDatenschutz />
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Footer />
          </Wrapper>
        </Section>
      </div>
    </React.Fragment>
  )
}
